import React, { useEffect } from "react"
import { navigate, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Logo from '../components/Logo'
import { ArrowRight, Video, ChevronDown } from '@geist-ui/react-icons'

import { StaticImage } from "gatsby-plugin-image"
import Typewriter from 'typewriter-effect/dist/core';

import LogoIcon from '../../static/assets/Header Icon.png'



export default function IndexPage ({data}) {

  function initiateTypewriter () {
    var typing = document.getElementById('typewriter');
    var typewriter = new Typewriter(typing, {
      loop: true,
    });
     
    typewriter
      .typeString('your nutrition')
      .pauseFor(600)
      .deleteChars(10)
      .typeString(' workplace')
      .pauseFor(600)
      .deleteChars(10)
      .typeString(' community')
      .pauseFor(600)
      .deleteChars(10)
      .typeString(' life')
      .pauseFor(3000)
      .deleteChars(9)
      .start();
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      initiateTypewriter();
    }, 500);
   return () => clearTimeout(timeout);
  }, []);


  return (
    <>
      <Helmet>
        <title>Enriched</title>
        <meta name="description" content="enhance the quality of your nutrition" />
      </Helmet>
      <div className="home-page">
        <div className="home-hero green">
            <img src={LogoIcon} />
            <div className="home-hero-text">
              <h2>
                <span className="bold">en•rich: </span>
                to improve or enhance the quality of
              </h2>
              <h3 id="typewriter"></h3>
              <a href="https://calendly.com/enriched/introductory-call" 
              target="blank"  className="livesite-schedule">Book Appointment</a>
            </div>
            <div className="down-arrow">
              <ChevronDown />
            </div>
        </div>
        <div className="mission-statement">
          <h2>enriched</h2>
          <div className="mission-inner">
            <div>
              We are trained scientists. Not saleswomen. We value <span className="large">truth over trends</span>, <span className="large">facts over fads</span> and <span className="large">science over speed</span>.<br /><br />
              We're on a mission to <span className="large">provide clarity</span> in a world of confusion, to impart the skills required to filter out the noise, 
              and to be a constant resource within an evolving scientific landscape.<br /><br />
              We've set out to <span className="large">demystify, deconstruct and debunk</span> nutrition 
              misinformation, to educate the world on what we do know about nutrition and health,
            </div>
            <div>
              and to translate complex scientific evidence into digestible lessons to <span className="large">improve your health</span> and well-being.<br /><br />
              We won't promise to 
              heal your gut, balance your hormones or boost your metabolism, nor will we promote superfoods or silver bullets. Instead we promise 
              to listen and learn, <span className="large">simplify and support</span>, educate and empower.<br /><br />
              We are enriched, and we <span className="large">can't wait to meet you</span>.
            </div>
          </div>
        </div>
        
        <div className="about">
          <div className="about-text">
            <Logo />
            <h2>Who We Are</h2>
            <div className="about-photos">
            <div className="about-photo" onClick={() => navigate('/nicole')}>
            <StaticImage
              src='../../static/assets/Nicole.png'
              alt="Nicole Ninteau"
            />
            <h4>Nicole Ninteau<br />
            <span style={{fontSize:'.7em'}}>PhD, MPH, CPT</span></h4>
            </div>
            <div className="about-photo" onClick={() => navigate('/anna')}>
            <StaticImage
              src='../../static/assets/Anna.png'
              alt="Anna Cataldo"
            />
            <h4>Anna Cataldo<br />
            <span style={{fontSize:'.7em'}}>MS, MPH, RD</span></h4>
            </div>
            </div>
            <p>Minimizing the confusion around nutrition science to maximize your health and quality of life</p>
          </div>
            <StaticImage
              src='../../static/assets/about background.jpg'
              imgStyle={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center center'
              }}
              className="background"
              layout="fullWidth"
              alt="About Enriched"
            />
        </div>
        <div className="services green">
          <Logo />
          <h2>What We Do</h2>
          <div className="cards">
            <div className="card">
              <h3>One-on-One</h3>
              <p>Meal plans, exercise programs, dietary assessments and private coaching to help you achieve and maintain your health-related goals.</p>
              <button onClick={() => navigate('/services/')}>Learn More <ArrowRight size={12} /></button>
            </div>
            <div className="card">
              <h3>Corporate Wellness</h3>
              <p>A connected and interactive experience centered around nutrition, exercise and mindfulness.</p>
              <button onClick={() => navigate('/services#businesses')}>Learn More <ArrowRight size={12} /></button>
            </div>
            <div className="card">
              <h3>Consulting</h3>
              <p>Expert opinions, analyses and recommendations to meet your specific business needs.</p>
              <button onClick={() => navigate('/services#businesses')}>Learn More <ArrowRight size={12} /></button>
            </div>
          </div>
        </div>
        <div className="contact">
          <Logo />
          <h2>Get Started</h2>
          <h4>Have questions?</h4>
          <p>Click below to schedule a complimentary Introductory Call.</p>
          <a href="https://calendly.com/enriched/introductory-call" 
              target="blank"  className="livesite-schedule">Schedule My Call</a>
        </div>
      </div>
    </>
  )
}
